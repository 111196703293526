'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRedux = require('react-redux');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reactRouter = require('react-router');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _ShortLinkList = require('./ShortLinkList.scss');

var _ShortLinkList2 = _interopRequireDefault(_ShortLinkList);

var _shortLink = require('redux/modules/shortLink');

var _components = require('components');

var _themeEnabled = require('helpers/themeEnabled');

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _sessionPermissions = require('../../helpers/sessionPermissions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Infinite = function Infinite(props) {
  return _react2.default.createElement(
    'div',
    null,
    props.children
  );
};


var md = {
  delete: require('react-icons/lib/md/delete'),
  add: require('react-icons/lib/md/add')
};

var ShortLinkList = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    user: state.auth.data.user,
    list: state.shortlink.list
  };
}, {
  get: _shortLink.getShortLinks,
  refreshShortLink: _shortLink.refreshShortLink
}), _dec(_class = (_temp = _class2 = function (_List) {
  (0, _inherits3.default)(ShortLinkList, _List);

  function ShortLinkList(props) {
    (0, _classCallCheck3.default)(this, ShortLinkList);

    var _this = (0, _possibleConstructorReturn3.default)(this, (ShortLinkList.__proto__ || (0, _getPrototypeOf2.default)(ShortLinkList)).call(this, props));

    _this.statusTranslations = {
      'ACTIVE': (0, _translations2.default)('URL ACTIVE'),
      'INACTIVE': (0, _translations2.default)('URL INACTIVE')
    };
    _this.state = {
      status: "ACTIVE"
    };
    _this.props.refreshShortLink();
    _this.props.get();
    return _this;
  }

  (0, _createClass3.default)(ShortLinkList, [{
    key: 'getLink',
    value: function getLink(item) {
      return {
        pathname: '/short_link/' + item._id,
        state: { modal: true, returnTo: '/short_link' }
      };
    }
  }, {
    key: 'renderListHeader',
    value: function renderListHeader() {
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_ShortLinkList2.default);

      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _ShortLinkList2.default.listheader },
          _react2.default.createElement('div', { className: _ShortLinkList2.default.handle }),
          _react2.default.createElement(
            'div',
            { className: _ShortLinkList2.default.name },
            (0, _translations2.default)('NAME')
          ),
          _react2.default.createElement(
            'div',
            { className: _ShortLinkList2.default.name },
            (0, _translations2.default)('SHORT LINK')
          ),
          _react2.default.createElement(
            'div',
            { className: _ShortLinkList2.default.date },
            (0, _translations2.default)('CREATED ON')
          ),
          _react2.default.createElement(
            'div',
            { className: _ShortLinkList2.default.valid },
            (0, _translations2.default)('STATUS')
          ),
          _react2.default.createElement(
            'div',
            { className: _ShortLinkList2.default.valid },
            (0, _translations2.default)('VISITS')
          ),
          _react2.default.createElement(
            'div',
            { className: _ShortLinkList2.default.valid },
            (0, _translations2.default)('PREVIEWS')
          ),
          _react2.default.createElement(
            _Allow2.default,
            { permissions: ['short:link:create'] },
            _react2.default.createElement(
              _reactRouter.Link,
              { to: '/short_link/new', className: _ShortLinkList2.default.linkholder },
              _react2.default.createElement(md.add, { size: 15 }),
              (0, _translations2.default)('NEW URL')
            )
          )
        )
      );
    }
  }, {
    key: 'renderInfiniteList',
    value: function renderInfiniteList() {
      var _this2 = this;

      var hidden = { display: 'none' };
      var visible = { display: 'block' };
      var infiniteStyle = this.props.isLoading || this.props.list.length > 0 ? visible : hidden;
      var linkstyle = this.props.isLoading || this.props.list.length > 0 ? hidden : visible;

      if (__SERVER__) {
        return _react2.default.createElement(_components.VirtualListItemLoad, null);
      }

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          'div',
          { style: infiniteStyle },
          _react2.default.createElement(_reactHelmet2.default, { title: 'Administrar Links' }),
          _react2.default.createElement(
            Infinite,
            {
              refresh: this.refreshRows,
              next: this.loadMoreRows,
              hasMore: this.state.hasMoreRows
            },
            this.props.list.filter(function (item, index) {
              return item.status !== 'DELETED';
            }).map(function (item, index) {
              return _react2.default.createElement(_components.VirtualListItemShortLink, {
                key: item._id,
                index: index,
                url_name: item.name,
                short_url: item.short_url,
                visits: item.visits,
                preview_visits: item.preview_visits,
                status: _this2.statusTranslations[item.status],
                created_on: item.created_on,
                link: (0, _sessionPermissions.sessionPermissions)('short:link:view') ? _this2.getLink(item) : null
              });
            })
          )
        ),
        _react2.default.createElement(_components.Linkholder, {
          style: linkstyle,
          to: {
            pathname: '/short_link/new',
            state: { modal: true, returnTo: '/short_link/' }
          },
          src: '/static/empty/studies.svg',
          text: (0, _translations2.default)('NOT URL FOUND'),
          button: (0, _translations2.default)('NEW URL')
        })
      );
    }
  }]);
  return ShortLinkList;
}(_components.List), _class2.propTypes = {
  list: _propTypes2.default.array.isRequired,
  get: _propTypes2.default.func.isRequired
}, _class2.defaultProps = {
  selected: []
}, _temp)) || _class);
exports.default = ShortLinkList;
module.exports = exports['default'];