'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProfilingSelector = exports.ProfilingConfigOpen = exports.ProfilingConfigEmail = exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _stringify = require('babel-runtime/core-js/json/stringify');

var _stringify2 = _interopRequireDefault(_stringify);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp, _dec2, _class3, _class4, _temp2, _dec3, _dec4, _dec5, _class5, _desc, _value, _class6, _class7, _temp3, _dec6, _dec7, _dec8, _class8, _dec9, _dec10, _class9, _dec11, _dec12, _class10, _class11, _temp4;

var _components = require('components');

var _draftJs = require('draft-js');

var _reactDnd = require('react-dnd');

var _workflows = require('redux/modules/workflows');

var _reactReduxLoadingBar = require('react-redux-loading-bar');

var _reactDndHtml5Backend = require('react-dnd-html5-backend');

var _reactDndHtml5Backend2 = _interopRequireDefault(_reactDndHtml5Backend);

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _featureEnabled = require('helpers/featureEnabled');

var _profiling = require('redux/modules/profiling');

var _interaction = require('redux/modules/interaction');

var _reachHistory = require('../../helpers/reachHistory');

var _Profiling = require('./Profiling.scss');

var _Profiling2 = _interopRequireDefault(_Profiling);

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  add: require('react-icons/lib/md/assignment'),
  add2: require('react-icons/lib/md/assignment-ind'),
  arrowBack: require('react-icons/lib/md/arrow-back'),
  message: require('react-icons/lib/md/email')
};

var items = [{
  category: "Información Básica",
  variables: [{
    name: "first_name",
    printable_value: "Nombre"
  }, {
    name: "last_name",
    printable_value: "Apellido"
  }, {
    name: "gender",
    printable_value: "Genero"
  }, {
    name: "date_of_birth",
    printable_value: "Nacimiento"
  }]
}, {
  category: "Información avanzada",
  variables: [{
    name: "height",
    printable_value: "Estatura"
  }, {
    name: "size",
    printable_value: "Talla"
  }, {
    name: "interests",
    printable_value: "Intereses"
  }, {
    name: "marital_status",
    printable_value: "Estado civil"
  }]
}, {
  category: "Intereses",
  variables: [{
    name: "events",
    printable_value: "Eventos"
  }, {
    name: "hobbys",
    printable_value: "Pasatiempos"
  }, {
    name: "sports",
    printable_value: "Deportes"
  }]
}, {
  category: "Nivel Socio-Económico",
  variables: [{
    name: "occupation",
    printable_value: "Ocupación"
  }, {
    name: "type_house",
    printable_value: "Tipo de Vivienda"
  }, {
    name: "payment_method",
    printable_value: "Tarjeta de Crédito"
  }, {
    name: "transport",
    printable_value: "Vehiculo Propio"
  }, {
    name: "insurance_type",
    printable_value: "Tipo de seguro"
  }, {
    name: "education",
    printable_value: "Educacion"
  }, {
    name: "traveler",
    printable_value: "Viajero"
  }, {
    name: "has_children",
    printable_value: "Dependientes"
  }]
}, {
  category: "Medios de comunicación",
  variables: [{
    name: "interaction_channel",
    printable_value: "Canal interacción"
  }, {
    name: "email",
    printable_value: "Email"
  }, {
    name: "internet",
    printable_value: "Tipo de teléfono"
  }, {
    name: "social_networks",
    printable_value: "Redes sociales"
  }, {
    name: "advertising_medium",
    printable_value: "Medio Publicitario"
  }]
}];

var ColorSource = {
  canDrag: function canDrag(props, monitor) {
    if (props.item.name === 'food') {
      return false;
    }
    var alreadyInList = props.selectedItems.filter(function (item) {
      return item._id === props.item._id;
    }).length > 0;
    var childAlreadyInList = !!props.item.variables && props.item.variables.filter(function (variable) {
      return props.selectedItems.filter(function (item) {
        return item._id === variable._id;
      }).length > 0;
    }).length > 0;
    var listFull = props.selectedItems.length > 9;
    return !alreadyInList && !childAlreadyInList && !listFull; // && !props.forbidDrag
  },
  beginDrag: function beginDrag(props) {
    return {
      text: props.text
    };
  },
  endDrag: function endDrag(props, monitor) {
    var wasDropped = !!monitor.getDropResult();

    if (wasDropped) {
      props.toggleForbidDrag();
      props.handleSelect(props.item);
    }
  }
};

var SourceBoxInner = (_dec = (0, _reactDnd.DragSource)('VARIABLE', ColorSource, function (connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}), _dec(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(SourceBoxInner, _React$Component);

  function SourceBoxInner() {
    (0, _classCallCheck3.default)(this, SourceBoxInner);
    return (0, _possibleConstructorReturn3.default)(this, (SourceBoxInner.__proto__ || (0, _getPrototypeOf2.default)(SourceBoxInner)).apply(this, arguments));
  }

  (0, _createClass3.default)(SourceBoxInner, [{
    key: 'canDrag',
    value: function canDrag() {
      var props = this.props;
      if (props.item.name === 'food') {
        return false;
      }
      var alreadyInList = props.selectedItems.filter(function (item) {
        return item._id === props.item._id;
      }).length > 0;
      var childAlreadyInList = !!props.item.variables && props.item.variables.filter(function (variable) {
        return props.selectedItems.filter(function (item) {
          return item._id === variable._id;
        }).length > 0;
      }).length > 0;
      var listFull = props.selectedItems.length > 9;
      return !alreadyInList && !childAlreadyInList && !listFull;
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          text = _props.text,
          children = _props.children,
          isDragging = _props.isDragging,
          connectDragSource = _props.connectDragSource,
          toggleForbidDrag = _props.toggleForbidDrag,
          item = _props.item;

      var forbidDrag = !this.canDrag();
      var opacity = isDragging || forbidDrag ? 0.4 : 1;

      var backgroundColor = void 0;
      var textStyle = void 0;
      var outerOpacity = void 0;
      var innerOpacity = void 0;
      var className = void 0;
      if (children) {
        innerOpacity = opacity;
        outerOpacity = 1;
        className = _Profiling2.default.sourceBoxParent;
      } else {
        innerOpacity = 1;
        outerOpacity = opacity;
        className = _Profiling2.default.sourceBoxChild;
      }

      return connectDragSource(_react2.default.createElement(
        'div',
        {
          style: {
            opacity: outerOpacity,
            cursor: forbidDrag ? 'default' : 'move'
          },
          className: className
        },
        _react2.default.createElement(
          'div',
          { className: _Profiling2.default.text, style: { opacity: innerOpacity } },
          text
        ),
        children
      ));
    }
  }]);
  return SourceBoxInner;
}(_react2.default.Component), _class2.propTypes = {
  connectDragSource: _propTypes2.default.func.isRequired,
  isDragging: _propTypes2.default.bool.isRequired,
  forbidDrag: _propTypes2.default.bool.isRequired,
  toggleForbidDrag: _propTypes2.default.func.isRequired
}, _temp)) || _class);

var SourceBox = function (_React$Component2) {
  (0, _inherits3.default)(SourceBox, _React$Component2);

  function SourceBox(props) {
    (0, _classCallCheck3.default)(this, SourceBox);

    var _this2 = (0, _possibleConstructorReturn3.default)(this, (SourceBox.__proto__ || (0, _getPrototypeOf2.default)(SourceBox)).call(this, props));

    _this2.state = {
      forbidDrag: false
    };
    return _this2;
  }

  (0, _createClass3.default)(SourceBox, [{
    key: 'render',
    value: function render() {
      var _this3 = this;

      return _react2.default.createElement(SourceBoxInner, (0, _extends3.default)({}, this.props, {
        forbidDrag: this.state.forbidDrag,
        toggleForbidDrag: function toggleForbidDrag() {
          return _this3.handleToggleForbidDrag();
        }
      }));
    }
  }, {
    key: 'handleToggleForbidDrag',
    value: function handleToggleForbidDrag() {
      this.setState({
        forbidDrag: !this.state.forbidDrag
      });
    }
  }]);
  return SourceBox;
}(_react2.default.Component);

var ColorTarget = {
  drop: function drop(props, monitor) {
    props.onDrop(monitor.getItem());
  }
};

var TargetBoxInner = (_dec2 = (0, _reactDnd.DropTarget)('VARIABLE', ColorTarget, function (connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
}), _dec2(_class3 = (_temp2 = _class4 = function (_React$Component3) {
  (0, _inherits3.default)(TargetBoxInner, _React$Component3);

  function TargetBoxInner() {
    (0, _classCallCheck3.default)(this, TargetBoxInner);
    return (0, _possibleConstructorReturn3.default)(this, (TargetBoxInner.__proto__ || (0, _getPrototypeOf2.default)(TargetBoxInner)).apply(this, arguments));
  }

  (0, _createClass3.default)(TargetBoxInner, [{
    key: 'render',
    value: function render() {
      var _props2 = this.props,
          canDrop = _props2.canDrop,
          isOver = _props2.isOver,
          connectDropTarget = _props2.connectDropTarget;

      var opacity = isOver ? 0.7 : 1;
      var backgroundColor = '#FFF';

      var items = this.props.items.map(function (item) {
        return { text: item.printable_value };
      });

      var drag = require('./drag.svg');
      return connectDropTarget(_react2.default.createElement(
        'div',
        { style: { backgroundColor: backgroundColor, opacity: opacity }, className: _Profiling2.default.targetBox },
        _react2.default.createElement(
          'p',
          { className: _Profiling2.default.head },
          'Arrastra aqu\xED tus variables de perfilaci\xF3n.'
        ),
        items.length == 0 && _react2.default.createElement('div', { className: _Profiling2.default.svg, dangerouslySetInnerHTML: { __html: drag } }),
        !canDrop && items && _react2.default.createElement(
          'ol',
          { className: _Profiling2.default.varsList },
          items.map(function (item, index) {
            return _react2.default.createElement(
              'li',
              { key: index },
              item.text
            );
          })
        )
      ));
    }
  }]);
  return TargetBoxInner;
}(_react2.default.Component), _class4.propTypes = {
  isOver: _propTypes2.default.bool.isRequired,
  canDrop: _propTypes2.default.bool.isRequired,
  draggingColor: _propTypes2.default.string,
  connectDropTarget: _propTypes2.default.func.isRequired,
  onDrop: _propTypes2.default.func.isRequired
}, _temp2)) || _class3);

var TargetBox = function (_React$Component4) {
  (0, _inherits3.default)(TargetBox, _React$Component4);

  function TargetBox(props) {
    (0, _classCallCheck3.default)(this, TargetBox);

    var _this5 = (0, _possibleConstructorReturn3.default)(this, (TargetBox.__proto__ || (0, _getPrototypeOf2.default)(TargetBox)).call(this, props));

    _this5.state = { items: [] };
    return _this5;
  }

  (0, _createClass3.default)(TargetBox, [{
    key: 'render',
    value: function render() {
      var _this6 = this;

      return _react2.default.createElement(TargetBoxInner, (0, _extends3.default)({}, this.props, {
        items: this.props.items,
        onDrop: function onDrop(color) {
          return _this6.handleDrop(color);
        }
      }));
    }
  }, {
    key: 'handleDrop',
    value: function handleDrop(val) {
      if (this.state.items.length < 10) {
        this.setState({
          items: this.state.items.concat(val)
        });
      }
    }
  }]);
  return TargetBox;
}(_react2.default.Component);

var ProfilingConfig = (_dec3 = (0, _reduxConnect.asyncConnect)([{
  key: 'profilings',
  promise: function promise(_ref) {
    var _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    var state = getState();
    if (state.profiling.list.length === 0) {
      return dispatch((0, _profiling.getProfilingJobs)(0, 5));
    }
    return null;
  }
}, {
  key: 'units',
  promise: function promise(_ref2) {
    var _ref2$store = _ref2.store,
        dispatch = _ref2$store.dispatch,
        getState = _ref2$store.getState;

    var state = getState();
    dispatch((0, _reactReduxLoadingBar.showLoading)());
    return dispatch((0, _workflows.getUnits)());
  }
}]), _dec4 = (0, _reactRedux.connect)(function (state) {
  return {
    units: state.workflows.list.profiling,
    mobileMode: state.mobile.mobileMode,
    sending_channels: state.workflows.list.sending_channels
  };
}, {
  setUnits: _workflows.setUnits, unitSelect: _workflows.unitSelect,
  unitUnselect: _workflows.unitUnselect, push: _reachHistory.push, hideLoading: _reactReduxLoadingBar.hideLoading,
  stopProfiling: _workflows.stopProfiling, getReservedKeywords: _interaction.getReservedKeywords
}), _dec5 = (0, _reactDnd.DragDropContext)(_reactDndHtml5Backend2.default), _dec3(_class5 = _dec4(_class5 = _dec5(_class5 = (_class6 = (_temp3 = _class7 = function (_React$Component5) {
  (0, _inherits3.default)(ProfilingConfig, _React$Component5);

  function ProfilingConfig(props) {
    (0, _classCallCheck3.default)(this, ProfilingConfig);

    var _this7 = (0, _possibleConstructorReturn3.default)(this, (ProfilingConfig.__proto__ || (0, _getPrototypeOf2.default)(ProfilingConfig)).call(this, props));

    _this7.state = {
      name: '',
      keywords: [],
      keyword: '',
      keywordErrors: '',
      message: '',
      subject: '',
      items: items,
      selectedItems: [],
      selectedTags: [],
      notification: '',
      editorState: _draftJs.EditorState.createEmpty()
    };
    return _this7;
  }

  (0, _createClass3.default)(ProfilingConfig, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var _this8 = this;

      this.props.hideLoading();
      var units = this.props.units.reduce(function (result, item) {
        result[item.variable.class.name] = item;return result;
      }, []);
      var compoundItems = items.reduce(function (categories, category, index) {
        categories[index] = category;
        categories[index].variables = category.variables.reduce(function (variables, variable) {
          variables.push((0, _extends3.default)({}, variable, units[variable.name]));
          return variables;
        }, []);
        return categories;
      }, []);

      setTimeout(function () {
        return _this8.setState({
          items: compoundItems,
          selectedItems: []
        });
      });
    }
  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this9 = this;

      this.props.getReservedKeywords().then(function (keywords) {
        _this9.setState((0, _extends3.default)({}, _this9.state, {
          keywords: keywords
        }));
      });
    }
  }, {
    key: 'setEditorReference',
    value: function setEditorReference(ref) {
      this.editorReferece = ref;
    }
  }, {
    key: 'handleEditorStateChange',
    value: function handleEditorStateChange(editorState) {
      var message = void 0;
      if (this.props.isEmail) {
        message = this.editorReferece.editor.innerHTML;
      } else {
        message = this.editorReferece.editor.innerText.replace(/^\s+|\s+$/g, '');
      }

      this.setState({
        editorState: editorState,
        message: message
      });
    }
  }, {
    key: 'handleClear',
    value: function handleClear() {
      this.setState({
        selectedItems: []
      });
    }
  }, {
    key: 'handleSubjectChange',
    value: function handleSubjectChange(subject) {
      this.setState({
        subject: subject
      });
    }
  }, {
    key: 'isValid',
    value: function isValid() {
      var _this10 = this;

      if (this.state.keywords.filter(function (r) {
        return r.keyword === '' + _this10.state.keyword;
      }).length > 0) {
        this.setState((0, _extends3.default)({}, this.state, {
          notification: (0, _translations2.default)('ERROR IN KEYWORD FIELD')
        }));
        return false;
      }
      if (this.props.isEmail && !this.state.subject) {
        this.setState((0, _extends3.default)({}, this.state, {
          notification: (0, _translations2.default)('ERROR IN SUBJECT FIELD')
        }));
        return false;
      }
      return true;
    }
  }, {
    key: 'save',
    value: function save() {
      switch (this.props.type) {
        case 'MERAKI':
          return this.saveMeraki();
          break;
        default:
          return this.saveStandard();
      }
    }
  }, {
    key: 'saveMeraki',
    value: function saveMeraki() {
      var _this11 = this;

      if (!this.isValid()) {
        return;
      }

      this.props.setMerakiUnits(this.state.name, this.state.keyword, this.state.selectedItems, this.state.message).then(function () {
        _this11.props.push({
          pathname: '/success',
          state: {
            goTo: '/integrations/meraki/',
            goToModal: false,
            message: (0, _translations2.default)('PROFILING START SUCCESS'),
            modal: true,
            returnTo: '/integrations/meraki/'
          }
        });
      }).catch(function (e) {
        _this11.props.push({
          pathname: '/fail',
          state: { goTo: '/integrations/meraki/', goToModal: false,
            message: e.message,
            modal: true, returnTo: '/integrations/meraki/' }
        });
      });
    }
  }, {
    key: 'saveStandard',
    value: function saveStandard() {
      var _this12 = this;

      if (!this.isValid()) {
        return;
      }

      var type = this.props.open ? "OPEN" : "CLOSED";

      var panel_filter = "{}";
      if (this.state.selectedTags.length > 0) {
        panel_filter = '{ "tags": ' + (0, _stringify2.default)(this.state.selectedTags) + '}';
      }

      var sendingChannels = [];
      if (this.props.isEmail === true) {
        var channel = this.props.sending_channels.filter(function (channel) {
          return channel.type === 'EMAIL';
        });
        sendingChannels = channel[0];
      } else {
        var _channel = this.props.sending_channels.filter(function (channel) {
          return channel.type === 'SMS';
        });
        sendingChannels = _channel[0];
      }

      this.props.setUnits(this.state.name, this.state.keyword, panel_filter, this.state.selectedItems, this.state.message, sendingChannels, type, this.state.subject).then(function () {
        _this12.props.push({
          pathname: '/success',
          state: {
            goTo: '/profiling/',
            goToModal: false,
            message: (0, _translations2.default)('PROFILING START SUCCESS'),
            modal: true,
            returnTo: '/contacts/'
          }
        });
      }).catch(function (e) {
        _this12.props.push({
          pathname: '/fail',
          state: { goTo: '/profiling/', goToModal: false,
            message: e.message,
            modal: true, returnTo: '/profiling/' }
        });
      });
    }
  }, {
    key: 'stopProfiling',
    value: function stopProfiling() {
      var _this13 = this;

      var type = this.props.open ? "OPEN" : "CLOSED";
      this.props.stopProfiling(type).then(function () {
        _this13.props.push({
          pathname: '/success',
          state: {
            goTo: '/profiling/',
            goToModal: false,
            message: (0, _translations2.default)('PROFILING STOP SUCCESS'),
            modal: true,
            returnTo: '/profiling/'
          }
        });
      });
    }
  }, {
    key: 'handleTagsChange',
    value: function handleTagsChange(selectedTags) {
      this.setState({
        selectedTags: selectedTags.map(function (tag) {
          return tag.value;
        })
      });
    }
  }, {
    key: 'handleSelectedVariable',
    value: function handleSelectedVariable(variable) {
      this.setState({
        selectedItems: this.state.selectedItems.concat(variable)
      });
    }
  }, {
    key: 'handleSelectedCategory',
    value: function handleSelectedCategory(category) {
      this.setState({
        selectedItems: this.state.selectedItems.concat(category.variables.map(function (variable) {
          return variable;
        }))
      });
    }
  }, {
    key: 'handleInputStateUpdated',
    value: function handleInputStateUpdated(prop, e) {
      var text = e.target.value;
      if (text.length <= 100) {
        this.setState((0, _defineProperty3.default)({}, prop, text));
      }
    }
  }, {
    key: 'handleKeyword',
    value: function handleKeyword(event) {
      if (!event.target.value || /^[a-z0-9]+$/i.test(event.target.value)) {
        var found = this.state.keywords.filter(function (r) {
          return r.keyword === '' + event.target.value;
        });
        var err = '';
        if (found.length > 0) {
          err = 'El keyword ya est\xE1 en uso para el estudio: ' + found[0].name;
        }
        this.setState((0, _extends3.default)({}, this.state, {
          keyword: event.target.value,
          keywordErrors: err
        }));
      }
    }
  }, {
    key: 'renderName',
    value: function renderName() {
      return _react2.default.createElement(
        'div',
        { className: _Profiling2.default.block },
        _react2.default.createElement(
          'div',
          { className: _Profiling2.default.name },
          _react2.default.createElement(
            'div',
            { className: _Profiling2.default.inputField },
            _react2.default.createElement('input', {
              id: 'profiling-name',
              type: 'text',
              maxLength: '48',
              value: this.state.name,
              onChange: this.handleInputStateUpdated.bind(this, 'name'),
              className: _Profiling2.default.input
            }),
            _react2.default.createElement(
              'label',
              { className: _Profiling2.default.label, htmlFor: 'profiling-name' },
              this.props.type === "MERAKI" ? (0, _translations2.default)('MERAKI PROFILING NAME') : (0, _translations2.default)('PROFILING NAME')
            )
          )
        ),
        this.props.type === "STANDARD" && !this.props.isEmail ? _react2.default.createElement(
          'div',
          { className: _Profiling2.default.name },
          _react2.default.createElement(
            'div',
            { className: _Profiling2.default.inputField },
            _react2.default.createElement('input', {
              id: 'profiling-keyword',
              type: 'text',
              maxLength: '48',
              value: this.state.keyword,
              onChange: this.handleKeyword,
              className: _Profiling2.default.input
            }),
            _react2.default.createElement(
              'label',
              { className: _Profiling2.default.label, htmlFor: 'profiling-keyword' },
              (0, _translations2.default)('PROFILING KEYWORD')
            ),
            _react2.default.createElement(
              'label',
              { className: _Profiling2.default.labelSub, htmlFor: 'profiling-keyword-sub' },
              (0, _translations2.default)('PROFILING KEYWORD SUBTITLE')
            )
          ),
          this.state.keywordErrors && _react2.default.createElement(
            'div',
            { className: _Profiling2.default.keywordError },
            this.state.keywordErrors
          )
        ) : null
      );
    }
  }, {
    key: 'renderMessage',
    value: function renderMessage() {
      return _react2.default.createElement(
        'div',
        { className: _Profiling2.default.inputField },
        _react2.default.createElement(
          'h3',
          { className: _Profiling2.default.configHeader },
          (0, _translations2.default)('PROFILING WELCOME MESSAGE')
        ),
        _react2.default.createElement(
          'div',
          { className: _Profiling2.default.messageField },
          this.props.isEmail ? _react2.default.createElement(
            'div',
            { className: _Profiling2.default.emai },
            _react2.default.createElement(_components.Email, {
              onSubjectChange: this.handleSubjectChange,
              editorReference: this.setEditorReference,
              editorState: this.state.editorState,
              onChange: this.handleEditorStateChange
            })
          ) : _react2.default.createElement('textarea', { id: "message-textarea",
            value: this.state.message,
            onChange: this.handleInputStateUpdated.bind(this, 'message')
          }),
          !this.props.isEmail ? _react2.default.createElement(
            'div',
            { className: _Profiling2.default.counter },
            this.state.message.length,
            '/100'
          ) : null
        )
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var _this14 = this;

      var typeTitle = this.props.open ? (0, _translations2.default)('OPEN PROFILING_TITLE') : (0, _translations2.default)('CLOSED PROFILING_TITLE');
      //const open = this.props.interaction.configuration.panel_source === 'OPEN'
      var type = this.props.open ? "OPEN" : "CLOSED";
      var items = this.state.items;

      var messages = ['invitation'];

      var activeProfilings = this.props.profilings ? this.props.profilings.filter(function (item) {
        return item.status === 'ACTIVE';
      }) : [];
      var emailOrSMSAlreadyRunning = false;
      activeProfilings.forEach(function (p) {
        p.sending_channels.forEach(function (s) {
          if (s.type === 'EMAIL' || s.type === 'SMS') {
            emailOrSMSAlreadyRunning = true;
          }
        });
      });

      return _react2.default.createElement(
        'article',
        { className: _Profiling2.default.main, id: 'study', ref: 'study' },
        _react2.default.createElement(_components.MobileBar, { returnTo: '/profiling/' }),
        _react2.default.createElement(
          'h2',
          { className: _Profiling2.default.title },
          typeTitle
        ),
        this.state.notification ? _react2.default.createElement(
          'label',
          { className: _Profiling2.default.error },
          this.state.notification
        ) : null,
        emailOrSMSAlreadyRunning && _react2.default.createElement(
          'label',
          { className: _Profiling2.default.error },
          'Ya hay una perfilaci\xF3n en ejecuci\xF3n. Si inicias una nueva \xE9sta se detendr\xE1.'
        ),
        _react2.default.createElement(
          'section',
          { className: _Profiling2.default.section },
          this.renderName()
        ),
        _react2.default.createElement(
          'section',
          { className: _Profiling2.default.middle },
          _react2.default.createElement(
            'h3',
            { className: _Profiling2.default.configHeader },
            'Selecci\xF3n de variables'
          ),
          _react2.default.createElement(
            'div',
            { style: { display: 'flex', padding: '10px 10px 30px', justifyContent: 'center', alignItems: 'end', background: '#f5f5f5' } },
            _react2.default.createElement(
              'div',
              { className: _Profiling2.default.sourceColumn },
              items.slice(0, 3).map(function (cat, index) {
                return _react2.default.createElement(
                  SourceBox,
                  { key: index, text: cat.category, handleSelect: _this14.handleSelectedCategory, item: cat, selectedItems: _this14.state.selectedItems },
                  cat.variables.map(function (variable, index2) {
                    return _react2.default.createElement(SourceBox, { key: index + '-' + index2, text: variable.printable_value, handleSelect: _this14.handleSelectedVariable, item: variable, selectedItems: _this14.state.selectedItems });
                  })
                );
              })
            ),
            _react2.default.createElement(
              'div',
              { style: { position: 'relative' } },
              _react2.default.createElement(TargetBox, { items: this.state.selectedItems }),
              _react2.default.createElement(
                'div',
                { className: _Profiling2.default.variableCount },
                'N\xFAmero de variables a perfilar ',
                this.state.selectedItems.length,
                '/10'
              ),
              _react2.default.createElement(
                'a',
                { onClick: this.handleClear, className: _Profiling2.default.clear },
                'Limpiar'
              )
            ),
            _react2.default.createElement(
              'div',
              { className: _Profiling2.default.sourceColumn + ' ' + _Profiling2.default.sourceColumnRight },
              items.slice(3, 6).map(function (cat, index) {
                return _react2.default.createElement(
                  SourceBox,
                  { key: index, text: cat.category, handleSelect: _this14.handleSelectedCategory, item: cat, selectedItems: _this14.state.selectedItems },
                  cat.variables.map(function (variable, index2) {
                    return _react2.default.createElement(SourceBox, { key: index + '-' + index2, text: variable.printable_value, handleSelect: _this14.handleSelectedVariable, item: variable, selectedItems: _this14.state.selectedItems });
                  })
                );
              })
            )
          )
        ),
        _react2.default.createElement(
          'section',
          { className: _Profiling2.default.section },
          _react2.default.createElement(
            'div',
            { className: _Profiling2.default.block },
            type === 'CLOSED' && _react2.default.createElement(
              _react2.default.Fragment,
              null,
              _react2.default.createElement(
                'h3',
                { className: _Profiling2.default.configHeader },
                ' Etiquetas a perfilar '
              ),
              _react2.default.createElement(_components.TagsSelect, {
                creatable: false,
                onChange: this.handleTagsChange
              }),
              this.renderMessage()
            )
          )
        ),
        _react2.default.createElement(
          'section',
          null,
          _react2.default.createElement(
            'div',
            { className: _Profiling2.default.footer },
            _react2.default.createElement(_components.Button, { className: _Profiling2.default.button, onClick: this.save, name: this.props.type === 'MERAKI' ? (0, _translations2.default)('MERAKI NEW PROFILING') : (0, _translations2.default)('NEW PROFILING'), color: 'green' })
          )
        )
      );
    }
  }]);
  return ProfilingConfig;
}(_react2.default.Component), _class7.propTypes = {
  location: _propTypes2.default.object.isRequired,
  units: _propTypes2.default.array.isRequired,
  unitSelect: _propTypes2.default.func.isRequired,
  unitUnselect: _propTypes2.default.func.isRequired,
  setUnits: _propTypes2.default.func.isRequired,
  push: _propTypes2.default.func.isRequired,
  stopProfiling: _propTypes2.default.func.isRequired,
  mobileMode: _propTypes2.default.bool.isRequired,
  hideLoading: _propTypes2.default.func.isRequired
}, _class7.defaultProps = {
  type: 'STANDARD',
  open: false,
  isEmail: false
}, _temp3), (_applyDecoratedDescriptor(_class6.prototype, 'setEditorReference', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class6.prototype, 'setEditorReference'), _class6.prototype), _applyDecoratedDescriptor(_class6.prototype, 'handleEditorStateChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class6.prototype, 'handleEditorStateChange'), _class6.prototype), _applyDecoratedDescriptor(_class6.prototype, 'handleClear', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class6.prototype, 'handleClear'), _class6.prototype), _applyDecoratedDescriptor(_class6.prototype, 'handleSubjectChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class6.prototype, 'handleSubjectChange'), _class6.prototype), _applyDecoratedDescriptor(_class6.prototype, 'save', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class6.prototype, 'save'), _class6.prototype), _applyDecoratedDescriptor(_class6.prototype, 'stopProfiling', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class6.prototype, 'stopProfiling'), _class6.prototype), _applyDecoratedDescriptor(_class6.prototype, 'handleTagsChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class6.prototype, 'handleTagsChange'), _class6.prototype), _applyDecoratedDescriptor(_class6.prototype, 'handleSelectedVariable', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class6.prototype, 'handleSelectedVariable'), _class6.prototype), _applyDecoratedDescriptor(_class6.prototype, 'handleSelectedCategory', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class6.prototype, 'handleSelectedCategory'), _class6.prototype), _applyDecoratedDescriptor(_class6.prototype, 'handleKeyword', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class6.prototype, 'handleKeyword'), _class6.prototype)), _class6)) || _class5) || _class5) || _class5);
exports.default = ProfilingConfig;
var ProfilingConfigEmail = exports.ProfilingConfigEmail = (_dec6 = (0, _reduxConnect.asyncConnect)([{
  key: 'units',
  promise: function promise(_ref3) {
    var _ref3$store = _ref3.store,
        dispatch = _ref3$store.dispatch,
        getState = _ref3$store.getState;

    var state = getState();
    dispatch((0, _reactReduxLoadingBar.showLoading)());
    return dispatch((0, _workflows.getUnits)());
  }
}]), _dec7 = (0, _reactRedux.connect)(function (state) {
  return {
    units: state.workflows.list.profiling,
    mobileMode: state.mobile.mobileMode
  };
}, {
  setUnits: _workflows.setUnits, unitSelect: _workflows.unitSelect,
  unitUnselect: _workflows.unitUnselect, push: _reachHistory.push, hideLoading: _reactReduxLoadingBar.hideLoading,
  stopProfiling: _workflows.stopProfiling, getReservedKeywords: _interaction.getReservedKeywords
}), _dec8 = (0, _reactDnd.DragDropContext)(_reactDndHtml5Backend2.default), _dec6(_class8 = _dec7(_class8 = _dec8(_class8 = function (_React$Component6) {
  (0, _inherits3.default)(ProfilingConfigEmail, _React$Component6);

  function ProfilingConfigEmail() {
    (0, _classCallCheck3.default)(this, ProfilingConfigEmail);
    return (0, _possibleConstructorReturn3.default)(this, (ProfilingConfigEmail.__proto__ || (0, _getPrototypeOf2.default)(ProfilingConfigEmail)).apply(this, arguments));
  }

  (0, _createClass3.default)(ProfilingConfigEmail, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(ProfilingConfig, (0, _extends3.default)({}, this.props, { isEmail: true }));
    }
  }]);
  return ProfilingConfigEmail;
}(_react2.default.Component)) || _class8) || _class8) || _class8);
var ProfilingConfigOpen = exports.ProfilingConfigOpen = (_dec9 = (0, _reduxConnect.asyncConnect)([{
  key: 'units',
  promise: function promise(_ref4) {
    var _ref4$store = _ref4.store,
        dispatch = _ref4$store.dispatch,
        getState = _ref4$store.getState;

    var state = getState();
    dispatch((0, _reactReduxLoadingBar.showLoading)());
    return dispatch((0, _workflows.getUnits)('OPEN'));
  }
}]), _dec10 = (0, _reactRedux.connect)(function (state) {
  return {
    units: state.workflows.list,
    mobileMode: state.mobile.mobileMode
  };
}, { setUnits: _workflows.setUnits, unitSelect: _workflows.unitSelect, unitUnselect: _workflows.unitUnselect, push: _reachHistory.push, hideLoading: _reactReduxLoadingBar.hideLoading, stopProfiling: _workflows.stopProfiling }), _dec9(_class9 = _dec10(_class9 = function (_React$Component7) {
  (0, _inherits3.default)(ProfilingConfigOpen, _React$Component7);

  function ProfilingConfigOpen() {
    (0, _classCallCheck3.default)(this, ProfilingConfigOpen);
    return (0, _possibleConstructorReturn3.default)(this, (ProfilingConfigOpen.__proto__ || (0, _getPrototypeOf2.default)(ProfilingConfigOpen)).apply(this, arguments));
  }

  (0, _createClass3.default)(ProfilingConfigOpen, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(ProfilingConfig, (0, _extends3.default)({}, this.props, { open: true }));
    }
  }]);
  return ProfilingConfigOpen;
}(_react2.default.Component)) || _class9) || _class9);
var ProfilingSelector = exports.ProfilingSelector = (_dec11 = (0, _reduxConnect.asyncConnect)([{
  key: 'units',
  promise: function promise(_ref5) {
    var _ref5$store = _ref5.store,
        dispatch = _ref5$store.dispatch,
        getState = _ref5$store.getState;

    var state = getState();
    dispatch((0, _reactReduxLoadingBar.showLoading)());
    return dispatch((0, _workflows.getUnits)('CLOSED'));
  }
}]), _dec12 = (0, _reactRedux.connect)(function (state) {
  return {
    account: state.account.data,
    mobileMode: state.mobile.mobileMode,
    units: state.workflows.list
  };
}), _dec11(_class10 = _dec12(_class10 = (_temp4 = _class11 = function (_React$Component8) {
  (0, _inherits3.default)(ProfilingSelector, _React$Component8);

  function ProfilingSelector() {
    (0, _classCallCheck3.default)(this, ProfilingSelector);
    return (0, _possibleConstructorReturn3.default)(this, (ProfilingSelector.__proto__ || (0, _getPrototypeOf2.default)(ProfilingSelector)).apply(this, arguments));
  }

  (0, _createClass3.default)(ProfilingSelector, [{
    key: 'stopProfiling',
    value: function stopProfiling(open) {
      var _this18 = this;

      var t = open ? "OPEN" : "CLOSED";
      this.props.stopProfiling(t).then(function () {
        _this18.props.pushState({
          pathname: '/success',
          state: {
            goTo: '/contacts/variables',
            goToModal: false,
            message: (0, _translations2.default)('PROFILING STOP SUCCESS'),
            modal: true,
            returnTo: '/contacts/variables'
          }
        });
      });
    }
  }, {
    key: 'renderButtons',
    value: function renderButtons() {
      var _this19 = this;

      if (!this.props.profilings) {
        return 'Error';
      }

      this.activeProfilings = this.props.profilings.filter(function (item) {
        return item.status === 'FINISHED';
      });
      return _react2.default.createElement(
        'div',
        { className: _Profiling2.default.stopButtons },
        this.activeProfilings.map(function (p, index) {
          if (p.panel_source == 'OPEN') {
            if (p.public_web_access.enabled && p.public_web_access.short_uri) {
              return _react2.default.createElement(
                'div',
                { className: _Profiling2.default.url, key: 'stop-' + index },
                _react2.default.createElement(
                  'div',
                  { className: _Profiling2.default.running },
                  (0, _translations2.default)('OPEN PROFILING RUNNING')
                ),
                _react2.default.createElement(
                  'div',
                  { className: _Profiling2.default.shareLink },
                  (0, _translations2.default)('PUBLIC URL FOR PROFILING'),
                  ': ',
                  _react2.default.createElement('br', null),
                  ' ',
                  _react2.default.createElement('br', null),
                  ' ',
                  p.public_web_access.short_uri,
                  ' ',
                  _react2.default.createElement(
                    'a',
                    { className: _Profiling2.default.TestLink, target: '_blank', href: p.public_web_access.short_uri },
                    'Abrir en nueva pesta\xF1a'
                  )
                ),
                _react2.default.createElement(
                  'a',
                  { className: _Profiling2.default.stop, onClick: _this19.stopProfiling.bind(_this19, true) },
                  (0, _translations2.default)('STOP OPEN PROFILING RUNNING')
                )
              );
            }
            return null;
          } else {
            return _react2.default.createElement(
              'div',
              { className: _Profiling2.default.url, key: 'stop-' + index },
              _react2.default.createElement(
                'div',
                { className: _Profiling2.default.running },
                (0, _translations2.default)('CLOSED PROFILING RUNNING')
              ),
              _react2.default.createElement(
                'a',
                { className: _Profiling2.default.stop, onClick: _this19.stopProfiling.bind(_this19, false) },
                (0, _translations2.default)('STOP CLOSED PROFILING RUNNING')
              )
            );
          }
        })
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var props = this.props;
      var channels = this.props.account.reach.channels;

      var hasEmail = !!channels.find(function (channel) {
        return channel.type === 'EMAIL' && channel.status === 'ACTIVE';
      });
      var smsAlreadyRunning = false;
      var emailAlreadyRunning = false;

      if (this.activeProfilings) {
        this.activeProfilings.forEach(function (p) {
          p.sending_channels.forEach(function (s) {
            if (s.type === 'EMAIL') {
              emailAlreadyRunning = true;
            }
            if (s.type === 'SMS') {
              smsAlreadyRunning = true;
            }
          });
        });
      }

      return _react2.default.createElement(
        'div',
        { className: _Profiling2.default.choiceModal, style: { width: '100%', height: '100%' } },
        _react2.default.createElement(_components.MobileBar, { returnTo: '/profiling/' }),
        _react2.default.createElement(
          _components.ButtonModal,
          null,
          _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              'h1',
              null,
              (0, _translations2.default)('NEW PROFILING PROMPT')
            ),
            smsAlreadyRunning ? _react2.default.createElement(
              'a',
              { className: 'action disabled' },
              _react2.default.createElement(
                'div',
                { className: _Profiling2.default.centerContent },
                _react2.default.createElement(md.add, { size: 30, color: '#616161' }),
                _react2.default.createElement(
                  'h2',
                  null,
                  (0, _translations2.default)('CLOSED PROFILING')
                )
              )
            ) : _react2.default.createElement(
              _reactRouter.Link,
              { className: 'action', to: {
                  pathname: '/profiling/closed/',
                  state: { modal: true, returnTo: '/profiling', fullModal: true }
                } },
              _react2.default.createElement(
                'div',
                { className: _Profiling2.default.centerContent },
                _react2.default.createElement(md.add, { size: 30, color: '#616161' }),
                _react2.default.createElement(
                  'h2',
                  null,
                  (0, _translations2.default)('CLOSED PROFILING')
                )
              )
            )
          ),
          (0, _featureEnabled.featureEnabled)('channel-email') && hasEmail ? _react2.default.createElement(
            'div',
            null,
            emailAlreadyRunning ? _react2.default.createElement(
              'a',
              { className: 'action disabled' },
              _react2.default.createElement(
                'div',
                { className: _Profiling2.default.centerContent },
                _react2.default.createElement(md.message, { size: 30, color: '#616161', className: _Profiling2.default.modalIcon }),
                _react2.default.createElement(
                  'h2',
                  null,
                  (0, _translations2.default)('EMAIL PROFILING')
                )
              )
            ) : (0, _featureEnabled.featureEnabled)('channel-email') ? _react2.default.createElement(
              _reactRouter.Link,
              { className: 'action', to: {
                  pathname: '/profiling/email/',
                  state: { modal: true, returnTo: '/profiling', fullModal: true }
                } },
              _react2.default.createElement(
                'div',
                { className: _Profiling2.default.centerContent },
                _react2.default.createElement(md.message, { size: 30, color: '#616161', className: _Profiling2.default.modalIcon }),
                _react2.default.createElement(
                  'h2',
                  null,
                  (0, _translations2.default)('EMAIL PROFILING')
                )
              )
            ) : _react2.default.createElement(
              'a',
              { className: 'action disabled' },
              _react2.default.createElement(
                'div',
                { className: _Profiling2.default.centerContent },
                _react2.default.createElement(md.message, { size: 30, color: '#616161', className: _Profiling2.default.modalIcon }),
                _react2.default.createElement(
                  'h2',
                  null,
                  (0, _translations2.default)('EMAIL PROFILING')
                )
              )
            )
          ) : null,
          (0, _featureEnabled.featureEnabled)('channel-web') ? _react2.default.createElement(
            _reactRouter.Link,
            { className: 'action', to: {
                pathname: '/profiling/open/',
                state: { modal: true, returnTo: '/profiling', fullModal: true }
              } },
            _react2.default.createElement(
              'div',
              { className: _Profiling2.default.centerContent },
              _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/openprofiling.svg'), className: _Profiling2.default.iconStyle }),
              _react2.default.createElement(
                'h2',
                null,
                (0, _translations2.default)('OPEN PROFILING')
              )
            )
          ) : _react2.default.createElement(
            'a',
            { className: 'action disabled' },
            _react2.default.createElement(
              'div',
              { className: _Profiling2.default.centerContent },
              _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/openprofiling.svg'), className: _Profiling2.default.iconStyle }),
              _react2.default.createElement(
                'h2',
                null,
                (0, _translations2.default)('OPEN PROFILING')
              )
            )
          )
        )
      );
    }
  }]);
  return ProfilingSelector;
}(_react2.default.Component), _class11.propTypes = {
  mobileMode: _propTypes2.default.bool.isRequired,
  location: _propTypes2.default.object.isRequired
}, _temp4)) || _class10) || _class10);