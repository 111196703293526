'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.actions = undefined;
exports.getPromotions = getPromotions;
exports.default = reducer;
exports.getPromotionByKeyword = getPromotionByKeyword;
exports.selectPromotionSMS = selectPromotionSMS;
exports.unselectPromotionSMS = unselectPromotionSMS;
exports.unselectAllPromotionsSMS = unselectAllPromotionsSMS;
exports.trimPromotionSMS = trimPromotionSMS;
exports.refreshPromotionSMS = refreshPromotionSMS;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var actions = exports.actions = new _apiAction2.default('INTERACTIVE_PROMOTIONS', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.TRIM, _apiAction.ACTION_TYPES.SELECT]);

var initialState = {
    loading: false,
    status: actions.APPEND,
    list: [],
    selected: [],
    data: {}
};
function getPromotions() {
    return {
        types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
        promise: function promise(client) {
            return client.get('/interactive/promotions');
        }
    };
}

function reducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    return actions.getStateForAction(state, action);
}

function getPromotionByKeyword(keyword) {
    return {
        types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
        promise: function promise(client) {
            return client.get('/interactive/promotion/keyword/' + keyword);
        }
    };
}

function selectPromotionSMS(index) {
    return {
        type: actions.SELECT,
        index: index
    };
}

function unselectPromotionSMS(index) {
    return {
        type: actions.SELECT_OFF,
        index: index
    };
}

function unselectAllPromotionsSMS() {
    return {
        type: actions.SELECT_NONE
    };
}

// Funciones de manejo de lista
function trimPromotionSMS(count) {
    return {
        type: actions.TRIM,
        count: count
    };
}

function refreshPromotionSMS() {
    return {
        type: actions.TRIM,
        count: 0
    };
}