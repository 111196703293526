'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _dec, _dec2, _class, _desc, _value, _class2, _class3, _temp; /* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable import/first */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _auth = require('redux/modules/auth');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

var _featureEnabled = require('../../helpers/featureEnabled');

var _sessionPermissions = require('../../helpers/sessionPermissions');

var _reachHistory = require('../../helpers/reachHistory');

var _reduxConnect = require('redux-connect');

var _account = require('redux/modules/account');

var _reactRedux = require('react-redux');

var _reports = require('redux/modules/reports');

var _coreDecorators = require('core-decorators');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _App = require('../../containers/App/App.scss');

var _App2 = _interopRequireDefault(_App);

var _TigoLogo = require('../TigoLogo/TigoLogo');

var _TigoLogo2 = _interopRequireDefault(_TigoLogo);

var _numbro = require('../../../node_modules/numbro/numbro');

var _numbro2 = _interopRequireDefault(_numbro);

var _reactSelect = require('react-select');

var _reactSelect2 = _interopRequireDefault(_reactSelect);

var _config = require('../../config');

var _config2 = _interopRequireDefault(_config);

var _redirectToUrlBeforeReload = require('../../helpers/redirectToUrlBeforeReload');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var MessagesItem = {
  title: 'Mensajes',
  icon: _react2.default.createElement('img', { alt: 'mensajes', src: (0, _useBasename2.default)('/static/tigo/menu/sms.svg') }),
  iconHover: _react2.default.createElement('img', { alt: 'mensajes', src: (0, _useBasename2.default)('/static/tigo/menu/sms-hover.svg') }),
  subItems: [{
    features: ['sms'],
    allFeatures: false,
    permissions: ['channels:sms:send:single'],
    allPermissions: false,
    title: 'SMS',
    path: '',
    child: [{
      features: ['sms'], permissions: ['channels:sms:send:single'], title: 'Nuevo Mensaje', path: '/messages/add'
    }, {
      features: ['sms'], permissions: ['channels:sms:send:file'], title: 'Nuevo mensaje por archivo', path: '/messages/addFile'
    }, { features: ['sms'], permissions: ['channels:sms:send:single'], title: "", separator: true, path: "", handleClick: true, type: 'v5' }, {
      features: ['sms'], permissions: ['transactions:list:incoming', 'transactions:list:outgoing'], allPermissions: false, title: 'Bitácora de mensajes', path: '/messagelogs', handleClick: true, type: 'v5'
    }, {
      features: ['sms'], permissions: ['transactions:files:list'], title: 'Listado de archivos', path: '/messages/files', handleClick: true, type: 'v5'
    }, {
      features: ['sms'], permissions: ['transactions:scheduled:list'], title: 'Mensajes Programados', path: '/messages/calendar'
    }, {
      features: ['dashboardotp'], permissions: ['transactions:list:incoming', 'transactions:list:outgoing'], allPermissions: false, title: 'Monitoreo de mensajes', path: '/messages/dashboard', handleClick: true, type: 'v5'
    }, {
      features: ['sms'], permissions: ['transactions:list:incoming'], title: (0, _translations2.default)('MESSAGES_RECEIVED'), path: '/messages/received'
    }, {
      features: ['sms'], permissions: ['transactions:list:outgoing'], title: (0, _translations2.default)('MESSAGES_SENT'), path: '/messages/'
    }]
  }, {
    features: ['rcs'],
    allFeatures: true,
    permissions: ['channels:rcs:send:single'],
    allPermissions: false,
    title: 'RCS',
    path: '',
    child: [{ features: ['rcs'], permissions: ['channels:rcs:send:single'], title: "Nuevo Mensaje", path: "/rcs/add?submenuItem=add", handleClick: true, type: 'v5' }, { features: ['rcs'], permissions: ['channels:rcs:send:file'], title: "Nuevo Mensaje por Archivo", path: "/rcs/file?submenuItem=file", handleClick: true, type: 'v5' }, { features: ['rcs'], permissions: ['channels:rcs:send:single'], title: "", separator: true, path: "", handleClick: true, type: 'v5' }, { features: ['rcs'], permissions: ['transactions:list:incoming', 'transactions:list:outgoing'], allPermissions: false, title: "Bitácora de mensajes", path: '/rcs/messagelogs?submenuItem=messagelogs', handleClick: true, type: 'v5' }, { features: ['rcs'], permissions: ['transactions:files:list'], title: "Listado de archivos", path: '/rcs/messages/files?submenuItem=messages/files', handleClick: true, type: 'v5' }]
  }, {
    features: ['template-sms', 'template-email'],
    allFeatures: false,
    permissions: ['channels:sms:template:list', 'channels:email:template:create'],
    allPermissions: false,
    title: 'Listado de plantillas',
    path: '',
    child: [{ permissions: ['channels:sms:template:list'], title: 'Plantillas SMS', path: '/messages/templates' }, { permissions: ['channels:email:template:create'], title: 'Plantillas Email', path: '/email/templates', handleClick: true, type: 'v5' }]
  }, {
    features: ['promotions'], permissions: ['promotions:list'], title: 'Administrar promociones', path: '/promotions', child: [], allFeatures: true
  }]
};

var ContactsItem = {
  permissions: ['contacts:view'],
  title: (0, _translations2.default)('MY CONTACTS'),
  icon: _react2.default.createElement('img', { alt: 'contactos', src: (0, _useBasename2.default)('/static/tigo/menu/contacts.svg') }),
  iconHover: _react2.default.createElement('img', { alt: 'contactos', src: (0, _useBasename2.default)('/static/tigo/menu/contacts-hover.svg') }),
  subItems: [{
    permissions: ['contacts:list'], title: (0, _translations2.default)('MANAGE_CONTACTS'), path: '/contacts', child: [], type: 'v5'
  }, {
    permissions: ['contacts:create:individual'], title: 'Agregar cliente', path: '/contacts/add', child: [], type: 'v5'
  }, {
    permissions: ['contacts:create:file'], title: 'Agregar por archivo', path: '/contacts/upload', child: []
  }, {
    permissions: ['contacts:list'], title: 'Listado de archivos', path: '/contacts/files', child: [], type: 'v5'
  },
  // {
  //   permissions: ['tags:list'], title: 'Listado de etiquetas', path: '/contacts/tags', child: []
  // },
  // { 
  //   permissions: ['tags:list'], title: "Listado de etiquetas", path: '/tags', handleClick: true, type: 'v5', child: [],
  // },
  {
    permissions: ['tags:list'],
    allPermissions: false,
    title: 'Etiquetas',
    path: '',
    child: [{
      permissions: ['landingpage:v5:list'], title: 'Listado de etiquetas', path: '/tags', handleClick: true, type: 'v5'
    }]
  }]
};

var InteractionsItem = {
  title: (0, _translations2.default)('MY STUDIES'),
  icon: _react2.default.createElement('img', { alt: 'flujos', src: (0, _useBasename2.default)('/static/tigo/menu/landings.svg') }),
  iconHover: _react2.default.createElement('img', { alt: 'flujos', src: (0, _useBasename2.default)('/static/tigo/menu/landings-hover.svg') }),
  subItems: [{
    features: ['studies'],
    permissions: ['study:list'],
    title: 'Administrar flujos',
    path: '/studies',
    child: [{ permissions: ['study:create:private'], title: 'Nuevo flujo por invitación', path: '/studies/study' }, { permissions: ['study:create:public'], title: 'Nuevo flujo público', path: '/studies/open' }, { permissions: ['study:create:api'], title: 'Nuevo flujo por api', path: '/studies/api' }]
  }, {
    features: ['campaigns'],
    permissions: ['campaign:list'],
    title: 'Administrar campañas',
    path: '/campaigns',
    child: [{ permissions: ['campaign:create'], title: 'Nueva campaña', path: '/campaigns/campaign' }]
  }, {
    features: ['shortlink'], permissions: ['short:link:list'], title: 'Administrar links', path: '/short_link', child: [{ permissions: ['short:link:report'], title: "Monitoreo de links", path: '/shortlinks/dashboard', handleClick: true, type: 'v5' }]
  }, {
    features: ['landingpage_v5'],
    allFeatures: false,
    permissions: ['landingpage:v5:list', 'landingpage:v5:create'],
    allPermissions: false,
    title: 'Landing Pages',
    path: '',
    child: [{
      permissions: ['landingpage:v5:list'], title: 'Landing Pages', path: '/landing-pages', handleClick: true, type: 'v5'
    }, {
      permissions: ['landingpage:v5:create'], title: 'Nueva landing page', path: '/landing-pages/landing', handleClick: true, type: 'v5'
    }, {
      permissions: ['landingpage:v5:report'], title: 'Monitoreo de landing pages', path: '/landing-pages/dashboard', handleClick: true, type: 'v5'
    }]
  }, {
    features: ['multimedia_files'],
    allFeatures: true,
    permissions: ['multimediafiles:v5:list', 'multimediafiles:v5:create', 'multimediafiles:v5:report'],
    allPermissions: false,
    title: (0, _translations2.default)('MULTIMEDIA_FILES'),
    path: '',
    child: [{
      permissions: ['multimediafiles:v5:list'], title: (0, _translations2.default)('MULTIMEDIA_FILES'), path: '/multimedia-files', handleClick: true, type: 'v5'
    }, {
      permissions: ['multimediafiles:v5:create'], title: (0, _translations2.default)('NEW_MULTIMEDIA_FILE'), path: '/multimedia-files/file', handleClick: true, type: 'v5'
    }, {
      permissions: ['multimediafiles:v5:report'], title: (0, _translations2.default)('MULTIMEDIA_FILES_MONITOR'), path: '/multimedia-files/dashboard', handleClick: true, type: 'v5'
    }]
  }, {
    features: ['interactive_promotions'], permissions: ['interactive:promotions:list'], title: (0, _translations2.default)('MANAGE_PROMOTIONS'), path: '/interactive/promotions', handleClick: true, type: 'v5', child: [{ permissions: ['interactive:promotions:create'], title: (0, _translations2.default)('NEW_PROMOTION'), path: '/interactive/promotions/create', handleClick: true, type: 'v5' }]
  }]
};

var ReportsItem = {
  permissions: ['account:reports:balance:view'],
  title: (0, _translations2.default)('MY REPORTS'),
  icon: _react2.default.createElement('img', { alt: 'reportes', src: (0, _useBasename2.default)('/static/tigo/menu/reports.svg') }),
  iconHover: _react2.default.createElement('img', { alt: 'reportes', src: (0, _useBasename2.default)('/static/tigo/menu/reports-hover.svg') }),
  subItems: [{
    title: 'Reportes de Hostigamiento', path: '/reports/dailyHarassment', child: []
  }, {
    features: ['reportes_csms'],
    permissions: ['account:reports:balance:view'],
    allPermissions: false,
    title: 'Facturación',
    path: '',
    child: [{ permissions: [], title: (0, _translations2.default)('BALANCE HISTORY'), path: '/reports/balanceHistory', handleClick: true, type: 'v5' }, { permissions: [], title: "Historial de Consumo por Mes Calendario", path: '/reports/MonthlyConsumptionHistory', handleClick: true, type: 'v5' }, { permissions: [], title: "Historial de Consumo por Día", path: '/reports/DailyConsumptionHistory', handleClick: true, type: 'v5' }, { permissions: [], title: "Historial de Consumo de usuario por Mes", path: '/reports/monthlyConsumptionByUser', handleClick: true, type: 'v5' }, { permissions: [], title: "Historial de Consumo de usuario por Día", path: '/reports/DailyConsumptionByUser', handleClick: true, type: 'v5' }]
  }, {
    permissions: ['account:reports:balance:view'],
    allPermissions: false,
    title: 'Reporte de Tráfico Diario',
    path: '',
    child: [{ permissions: [], title: "Historial de Tráfico por Día", path: '/reports/DailyTraffic', handleClick: true, type: 'v5' }, { permissions: [], title: "Monitoreo de Errores", path: '/reports/ErrorMonitoring', handleClick: true, type: 'v5' }, { permissions: [], title: "Reporte general", path: '/vreports/Generals', handleClick: true, type: 'v5' }]
  }]
};

var ConversationsItem = {
  title: 'Conversaciones',
  type: 'v5',
  icon: _react2.default.createElement('img', { alt: 'conversations', src: (0, _useBasename2.default)('/static/tigo/menu/conversations.svg') }),
  iconHover: _react2.default.createElement('img', { alt: 'conversations', src: (0, _useBasename2.default)('/static/tigo/menu/conversations-hover.svg') }),
  path: '/conversations',
  subItems: [],
  feature: 'conversations'
};

var AccountItem = {
  title: (0, _translations2.default)('ACCOUNT'),
  icon: _react2.default.createElement('img', { alt: 'contactos', src: (0, _useBasename2.default)('/static/tigo/menu/user.svg') }),
  iconHover: _react2.default.createElement('img', { alt: 'contactos', src: (0, _useBasename2.default)('/static/tigo/menu/user-hover.svg') }),
  subItems: [{
    title: (0, _translations2.default)('ACCOUNT DETAIL'), path: '/accountDetails', child: [], type: 'v5'
  }, {
    features: ['application_sso'], permissions: ['account:configs:sso'], title: "Configuración de SSO", path: '/ssoConfig', child: [], type: 'v5', allPermissions: true
  }, {
    title: (0, _translations2.default)('API CONFIG'), path: '/account/apidetail/', child: []
  }, {
    title: (0, _translations2.default)('USER CONFIG'), path: '/users/', child: []
  }].concat((0, _toConsumableArray3.default)(_config2.default.allow2FA ? [{
    title: (0, _translations2.default)('AUTH CONFIG'), path: '/authenticationConfig', child: [], type: 'v5'
  }] : []), [{
    title: (0, _translations2.default)('LOGOUT'), path: '/logout', child: []
  }]).filter(Boolean)
};

var VerticalMenu = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'account',
  promise: function promise(_ref) {
    var _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    var state = getState();
    if (!state.account.data.reach) {
      return dispatch((0, _account.getAccount)());
    }
    return null;
  }
}, {
  key: 'auth',
  promise: function promise(_ref2) {
    var _ref2$store = _ref2.store,
        dispatch = _ref2$store.dispatch,
        getState = _ref2$store.getState;

    console.log('auth', (0, _auth.isLoaded)(getState()));
    if (!(0, _auth.isLoaded)(getState())) {
      return dispatch((0, _auth.load)());
    }
    return null;
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    auth: state.auth,
    reach: state.account.data.reach,
    account: state.account.data,
    currentAccount: state.auth.current_account
  };
}, {
  push: _reachHistory.push, logout: _auth.logout, getBalance: _reports.getBalance, getAccount: _account.getAccount, changeAccount: _auth.changeAccount
}), _dec(_class = _dec2(_class = (_class2 = (_temp = _class3 = function (_React$Component) {
  (0, _inherits3.default)(VerticalMenu, _React$Component);

  function VerticalMenu(props) {
    (0, _classCallCheck3.default)(this, VerticalMenu);

    var _this = (0, _possibleConstructorReturn3.default)(this, (VerticalMenu.__proto__ || (0, _getPrototypeOf2.default)(VerticalMenu)).call(this, props));

    _this.f = function (n) {
      return (0, _numbro2.default)(n).format(_this.format);
    };

    _this.state = {
      openBar: false,
      multipleOptions: false,
      itemSelected: null,
      optionsSelected: null,
      subItemsTitle: null,
      itemHover: null,
      accountShortCode: null,
      balanceData: null,
      account: _this.props.auth.data ? _this.props.auth.data.account_list.find(function (a) {
        return a.is_active;
      }) : {},
      user: _this.props.auth.data ? _this.props.auth.data.user : {},
      menuItems: [{
        title: 'Menu principal',
        type: 'v4',
        icon: _react2.default.createElement('img', { alt: 'dashboard', src: (0, _useBasename2.default)('/static/tigo/menu/home.svg') }),
        iconHover: _react2.default.createElement('img', { alt: 'dashboard', src: (0, _useBasename2.default)('/static/tigo/menu/home-hover.svg') }),
        path: '/dashboard',
        subItems: []
      }],
      resellerMenuItems: [{
        permissions: ['reseller:subaccount:list'],
        title: (0, _translations2.default)('SUBACCOUNTS'),
        icon: _react2.default.createElement('img', { alt: 'dashboard', src: (0, _useBasename2.default)('/static/tigo/menu/home.svg') }),
        iconHover: _react2.default.createElement('img', { alt: 'dashboard', src: (0, _useBasename2.default)('/static/tigo/menu/home-hover.svg') }),
        path: '/reseller',
        subItems: []
      }, {
        permissions: ['reseller:files:report'],
        title: 'Nuevo mensaje',
        icon: _react2.default.createElement('img', { alt: 'interacciones', src: (0, _useBasename2.default)('/static/tigo/menu/conversation.svg') }),
        iconHover: _react2.default.createElement('img', { alt: 'interacciones', src: (0, _useBasename2.default)('/static/tigo/menu/conversations-hover.svg') }),
        path: '',
        subItems: [{
          permissions: ['reseller:files:report'],
          title: 'Monitoreo de archivos',
          path: '/messages/files/dashboard',
          handleClick: true,
          type: 'v5',
          child: []
        }, {
          permissions: ['transactions:files:list'],
          title: 'Listado de archivos',
          path: '/messages/files',
          handleClick: true,
          type: 'v5',
          child: []
        }]
      }, {
        permissions: ['reseller:policies:antispam:list'],
        title: (0, _translations2.default)('POLICIES'),
        icon: _react2.default.createElement('img', { alt: 'flujos', src: (0, _useBasename2.default)('/static/tigo/menu/landings.svg') }),
        iconHover: _react2.default.createElement('img', { alt: 'flujos', src: (0, _useBasename2.default)('/static/tigo/menu/landings-hover.svg') }),
        path: '/reseller/policies/',
        subItems: []
      }, {
        permissions: ['reseller:subaccount:reports:balance:view'],
        title: 'Reportes',
        icon: _react2.default.createElement('img', { alt: 'reportes', src: (0, _useBasename2.default)('/static/tigo/menu/reports.svg') }),
        iconHover: _react2.default.createElement('img', { alt: 'reportes', src: (0, _useBasename2.default)('/static/tigo/menu/reports-hover.svg') }),
        path: '',
        subItems: [{
          title: (0, _translations2.default)('BALANCE'), path: '/reseller/balance/', child: []
        }, {
          title: (0, _translations2.default)('HARASSMENT'), path: '/reseller/dailyHarassment/', child: []
        }, {
          features: ['reportes_csms'],
          permissions: ['account:reports:balance:view'],
          allPermissions: false,
          title: 'Facturación',
          path: '',
          child: [{ permissions: [], title: (0, _translations2.default)('BALANCE HISTORY'), path: '/reports/balanceHistory', handleClick: true, type: 'v5' }, { permissions: [], title: "Historial de Consumo por Mes Calendario", path: '/reports/MonthlyConsumptionHistory', handleClick: true, type: 'v5' }, { permissions: [], title: "Historial de Consumo por Día", path: '/reports/DailyConsumptionHistory', handleClick: true, type: 'v5' }, { permissions: [], title: "Historial de Consumo de usuario por Mes", path: '/reports/monthlyConsumptionByUser', handleClick: true, type: 'v5' }, { permissions: [], title: "Historial de Consumo de usuario por Día", path: '/reports/DailyConsumptionByUser', handleClick: true, type: 'v5' }]
        }, {
          permissions: ['account:reports:balance:view'],
          allPermissions: false,
          title: 'Reporte de Tráfico Diario',
          path: '',
          child: [{ permissions: [], title: "Historial de Tráfico por Día", path: '/reports/DailyTraffic', handleClick: true, type: 'v5' }, { permissions: [], title: "Monitoreo de Errores", path: '/reports/ErrorMonitoring', handleClick: true, type: 'v5' }, { permissions: [], title: "Reporte general", path: '/reports/Generals', handleClick: true, type: 'v5' }]
        }]
      }, {
        permissions: ['reseller:subaccount:user:list'],
        title: (0, _translations2.default)('USERS'),
        icon: _react2.default.createElement('img', { alt: 'contactos', src: (0, _useBasename2.default)('/static/tigo/menu/contacts.svg') }),
        iconHover: _react2.default.createElement('img', { alt: 'contactos', src: (0, _useBasename2.default)('/static/tigo/menu/contacts-hover.svg') }),
        path: '/reseller/users/',
        subItems: []
      }, {
        permissions: [],
        title: (0, _translations2.default)('MY STUDIES'),
        icon: _react2.default.createElement('img', { alt: 'interacciones', src: (0, _useBasename2.default)('/static/tigo/menu/landings.svg') }),
        iconHover: _react2.default.createElement('img', { alt: 'interacciones', src: (0, _useBasename2.default)('/static/tigo/menu/landings-hover.svg') }),
        path: '',
        subItems: [{
          permissions: ['short:link:report'],
          title: 'Monitoreo de links',
          path: '/shortlinks/dashboard',
          handleClick: true,
          type: 'v5',
          child: []
        }]
      }]
    };
    return _this;
  }

  (0, _createClass3.default)(VerticalMenu, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      (0, _redirectToUrlBeforeReload.redirectToUrlBeforeReload)(this.props.currentAccount);
      this.setPaletteVariables(this.props.palette);
      // eslint-disable-next-line prefer-const
      var newItems = this.state.menuItems;
      newItems.push(MessagesItem);
      newItems.push(ContactsItem);
      if ((0, _featureEnabled.featureEnabled)('studies') || (0, _featureEnabled.featureEnabled)('shortlink') || (0, _featureEnabled.featureEnabled)('campaigns')) newItems.push(InteractionsItem);
      newItems.push(ReportsItem);
      if ((0, _featureEnabled.featureEnabled)('conversation')) newItems.push(ConversationsItem);
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState((0, _extends3.default)({}, this.state, { menuItems: newItems }));
      if (this.props.auth.data) {
        this.updateReport();
      }
    }
  }, {
    key: 'setPaletteVariables',
    value: function setPaletteVariables(params) {
      document.body.style.setProperty('--vertical_menu-open-button-background', '' + params.button.background);
      document.body.style.setProperty('--vertical_menu-primary-hover', '' + params.hover.primary);
      document.body.style.setProperty('--vertical_menu-secondary-hover', '' + params.hover.secondary);
      document.body.style.setProperty('--vertical_menu-primary-font', '' + params.font.primary);
      document.body.style.setProperty('--vertical_menu-secondary-font', '' + params.font.secondary);
      document.body.style.setProperty('--vertical_menu-tertiary-font', '' + params.font.tertiary);
      document.body.style.setProperty('--vertical_menu-primary-background', '' + params.background.primary);
      document.body.style.setProperty('--vertical_menu-secondary-background', '' + params.background.secondary);
    }
  }, {
    key: 'updateReport',
    value: function updateReport() {
      var _this2 = this;

      this.props.getBalance().then(function (balance) {
        // eslint-disable-next-line prefer-const
        var data = {};
        if (balance) {
          if (balance.channel) {
            _this2.setState((0, _extends3.default)({}, _this2.state, { accountShortCode: balance.channel.identifier }));
          }
          if (balance.current_package) {
            var _balance$current_pack = balance.current_package,
                credit_base_limit = _balance$current_pack.credit_base_limit,
                credit_limit = _balance$current_pack.credit_limit,
                credit_extra_limit = _balance$current_pack.credit_extra_limit;

            var cycle = balance.cycle || '';
            var packageMessages = credit_base_limit;
            var usedMessages = credit_limit - balance.current_package.balance;
            var availableMessages = usedMessages < credit_base_limit ? balance.current_package.balance - credit_extra_limit : 0;
            var availableExtras = usedMessages > credit_base_limit ? credit_limit - usedMessages : credit_extra_limit;
            var base_percent = credit_base_limit ? Math.floor(availableMessages / credit_base_limit * 100).toFixed(2) : 0;
            var extras_percent = credit_extra_limit ? Math.floor(availableExtras / credit_extra_limit * 100).toFixed(2) : 0;
            var sms = {
              messages: {
                percent: base_percent,
                remaining: _this2.f(availableMessages),
                package: _this2.f(packageMessages)
              },
              extras: {
                percent: extras_percent,
                consumed: _this2.f(credit_extra_limit - availableExtras),
                remaining: _this2.f(availableExtras),
                limit: _this2.f(credit_extra_limit)
              },
              cycle: cycle
              // eslint-disable-next-line prefer-destructuring
            };var email = balance.email;
            email.balance = email.balance ? _this2.f(email.balance) : null;
            email.credit_base_limit = email.credit_limit ? _this2.f(email.credit_limit) : null;
            data.sms = sms;
            data.email = email;
          }
        }
        _this2.setState((0, _extends3.default)({}, _this2.state, { balanceData: data }));
      });
    }
  }, {
    key: 'handleLogout',
    value: function handleLogout() {
      this.props.push('/');
      this.props.logout().then(function () {
        window.location = '/';
      });
    }
  }, {
    key: 'navigateAfterChange',
    value: function navigateAfterChange(accountType) {
      if (accountType === 'Reseller') {
        sessionStorage.removeItem("urlBeforeReload");
        window.location = _config2.default.basename + '/reseller';
        this.props.push('/reseller');
      } else {
        window.location = _config2.default.basename + '/dashboard';
      }
    }
  }, {
    key: 'changeAccount',
    value: function changeAccount(element) {
      var _this3 = this;

      var accountType = this.props.currentAccount.account_type;
      sessionStorage.setItem("urlBeforeReload", window.location.href);
      this.props.changeAccount(element.value).then(function () {
        _this3.navigateAfterChange(accountType);
      }).catch(function () {
        _this3.navigateAfterChange(accountType);
      });
    }
  }, {
    key: 'changeBarState',
    value: function changeBarState() {
      if (this.state.openBar) {
        this.setState((0, _extends3.default)({}, this.state, { itemSelected: null, optionsSelected: null, openBar: false, multipleOptions: false
        }));
      } else {
        this.setState((0, _extends3.default)({}, this.state, { openBar: true }));
      }
    }
  }, {
    key: 'actionItem',
    value: function actionItem(item) {
      if (item.path) {
        if (item.path === '/logout') {
          this.handleLogout();
        } else if (item.type === 'v5') {
          window.location.replace('/v5' + item.path);
        } else {
          this.props.push(item.path);
        }
      }
      this.setState((0, _extends3.default)({}, this.state, { openBar: false }));
    }
  }, {
    key: 'itemClick',
    value: function itemClick(item) {
      if (item.subItems.length > 0) {
        this.setState((0, _extends3.default)({}, this.state, { multipleOptions: true, itemSelected: item, subItemsTitle: item.subItemsTitle
        }));
      } else if (this.state.itemSelected === item) {
        this.setState((0, _extends3.default)({}, this.state, { multipleOptions: false, itemSelected: item }));
      }
    }
  }, {
    key: 'changeMenuSelectedValue',
    value: function changeMenuSelectedValue(item) {
      if (item.subItems.length > 0) {
        this.setState((0, _extends3.default)({}, this.state, { multipleOptions: true, itemSelected: item, subItemsTitle: item.subItemsTitle, openBar: true
        }));
      } else {
        this.actionItem({ type: item.type || 'v4', path: item.path });
        this.setState((0, _extends3.default)({}, this.state, { multipleOptions: false, itemSelected: item, openBar: false
        }));
      }
    }
  }, {
    key: 'iconClick',
    value: function iconClick(item) {
      if (this.state.openBar) {
        if (this.state.itemSelected === item) {
          this.setState((0, _extends3.default)({}, this.state, { openBar: false }));
        } else {
          this.changeMenuSelectedValue(item);
        }
      } else {
        this.changeMenuSelectedValue(item);
      }
    }
  }, {
    key: 'renderSubItems',
    value: function renderSubItems(item, index) {
      var _this4 = this;

      var showItem = true;
      if (item.features) {
        item.features.map(function (feature) {
          if (!(0, _featureEnabled.featureEnabled)(feature) && item.allFeatures) {
            showItem = false;
          }
        });
      }
      if (item.permissions) {
        item.permissions.map(function (permission) {
          if (!(0, _sessionPermissions.sessionPermissions)(permission) && item.allPermissions) showItem = false;
        });
      }
      return showItem && _react2.default.createElement(
        'div',
        { className: _App2.default.multipleOptionsItem, key: index },
        _react2.default.createElement(
          'div',
          { className: _App2.default.multipleOptionsItemTitle, onClick: function onClick() {
              return _this4.actionItem({ type: item.type || 'v4', path: item.path });
            } },
          item.title
        ),
        _react2.default.createElement(
          'div',
          { className: _App2.default.multipleOptionsItemOptions },
          item.child.length > 0 && item.child.map(function (option, optionIndex) {
            return _this4.renderOption(option, optionIndex);
          })
        )
      );
    }
  }, {
    key: 'renderOption',
    value: function renderOption(option, optionIndex) {
      var _this5 = this;

      var showItem = true;
      if (option.features) {
        option.features.map(function (feature) {
          if (!(0, _featureEnabled.featureEnabled)(feature)) showItem = false;
        });
      }
      if (option.permissions) {
        option.permissions.map(function (permission) {
          if (!(0, _sessionPermissions.sessionPermissions)(permission)) showItem = false;
        });
      }
      return showItem && _react2.default.createElement(
        'div',
        { style: option.separator && { borderBottom: '1px solid white', minHeight: '0' }, className: _App2.default.multipleOptionsItemOption, key: 'item-child ' + optionIndex, onClick: function onClick() {
            return _this5.actionItem({ type: option.type || 'v4', path: option.path });
          } },
        option.title
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var _this6 = this;

      var itemsToRender = this.state.account.account_type === 'Reseller' ? this.state.resellerMenuItems : this.state.menuItems;
      if (this.state.account.account_list === undefined && this.state.user.username === undefined) {
        return _react2.default.createElement('div', null);
      }
      return _react2.default.createElement(
        'div',
        { className: this.state.openBar ? this.state.multipleOptions ? _App2.default.verticalMenuContainerOpenMultiple : _App2.default.verticalMenuContainerOpen : _App2.default.verticalMenuContainer },
        _react2.default.createElement(
          'div',
          { className: this.state.openBar ? _App2.default.openBarButtonOpen : _App2.default.openBarButton, onClick: function onClick() {
              return _this6.changeBarState();
            }, key: 'open-close-circle' },
          _react2.default.createElement(
            'svg',
            { width: '4', height: '8', viewBox: '0 0 4 8', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
            _react2.default.createElement('path', { d: 'M2.695 4.001.375 6.65a.54.54 0 0 0-.125.353c0 .132.047.259.129.352a.412.412 0 0 0 .308.146.411.411 0 0 0 .308-.144l2.627-3a.537.537 0 0 0 .128-.343.542.542 0 0 0-.115-.348L.997.645A.411.411 0 0 0 .69.5.412.412 0 0 0 .38.646a.539.539 0 0 0-.129.352.54.54 0 0 0 .125.353L2.695 4z', fill: '#49525F' })
          )
        ),
        _react2.default.createElement(
          'div',
          { className: _App2.default.verticalMenu },
          _react2.default.createElement(
            'div',
            { className: _App2.default.menuItemsContainer },
            _react2.default.createElement(
              'div',
              { className: this.state.openBar ? _App2.default.accountPartnerLogo : _App2.default.accountPartnerLogoClosed },
              this.state.openBar ? _react2.default.createElement(_TigoLogo2.default, null) : _react2.default.createElement('img', { alt: 'logo', src: (0, _useBasename2.default)('/static/tigo/menu/go.webp') })
            ),
            itemsToRender.map(function (item, index) {
              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                _react2.default.createElement(
                  'div',
                  { className: _App2.default.menuItem, key: index, onClick: function onClick() {
                      return _this6.iconClick(item);
                    }, onMouseEnter: function onMouseEnter() {
                      return _this6.setState((0, _extends3.default)({}, _this6.state, { itemHover: item.title }));
                    }, onMouseLeave: function onMouseLeave() {
                      return _this6.setState((0, _extends3.default)({}, _this6.state, { itemHover: null }));
                    } },
                  _react2.default.createElement(
                    'div',
                    { className: _App2.default.menuItemIcon },
                    item.iconHover ? _this6.state.itemHover === item.title ? item.iconHover : item.icon : item.icon
                  ),
                  _this6.state.openBar ? _react2.default.createElement(
                    'div',
                    { className: _App2.default.menuItemTitle },
                    item.title
                  ) :
                  // eslint-disable-next-line react/jsx-indent
                  _react2.default.createElement(
                    'div',
                    { className: _App2.default.menuItemTitleHover },
                    item.title
                  )
                )
              );
            })
          ),
          _react2.default.createElement(
            'div',
            { className: _App2.default.accountInfo, onMouseEnter: function onMouseEnter() {
                return _this6.setState((0, _extends3.default)({}, _this6.state, { itemHover: AccountItem.title }));
              }, onMouseLeave: function onMouseLeave() {
                return _this6.setState((0, _extends3.default)({}, _this6.state, { itemHover: null }));
              } },
            _react2.default.createElement(
              'div',
              { className: _App2.default.accountInfoItem },
              _react2.default.createElement(
                'div',
                { className: _App2.default.menuItem },
                _react2.default.createElement(
                  'div',
                  { className: _App2.default.menuItemIcon, onClick: function onClick() {
                      return _this6.iconClick(AccountItem);
                    } },
                  this.state.itemHover === AccountItem.title ? AccountItem.iconHover : AccountItem.icon
                ),
                this.state.openBar ? _react2.default.createElement(
                  'div',
                  { className: _App2.default.menuItemTitle },
                  this.state.account.name,
                  _react2.default.createElement(
                    'div',
                    { className: _App2.default.menuItemTitleEmail },
                    this.state.user.username
                  ),
                  _react2.default.createElement(
                    'div',
                    { className: _App2.default.menuItemTitleShortCode, title: (0, _translations2.default)('ACCOUNT PHONE') },
                    this.state.accountShortCode
                  ),
                  this.state.balanceData && _react2.default.createElement(
                    'div',
                    { className: _App2.default.menuItemTitleBalance },
                    ' ',
                    _react2.default.createElement(
                      'span',
                      null,
                      'Mensajes '
                    ),
                    this.state.balanceData.sms.messages.remaining,
                    '/',
                    this.state.balanceData.sms.messages.package
                  ),
                  (0, _featureEnabled.featureEnabled)('email') && this.state.balanceData.email.balance && this.state.balanceData.email.credit_base_limit && _react2.default.createElement(
                    'div',
                    { className: _App2.default.menuItemTitleBalance },
                    _react2.default.createElement(
                      'span',
                      null,
                      'Email '
                    ),
                    this.state.balanceData.email.balance,
                    '/',
                    this.state.balanceData.email.credit_base_limit
                  )
                ) :
                // eslint-disable-next-line react/jsx-indent
                _react2.default.createElement(
                  'div',
                  { className: _App2.default.menuItemTitleHover },
                  AccountItem.title
                )
              )
            )
          )
        ),
        this.state.multipleOptions && this.state.openBar && _react2.default.createElement(
          'div',
          { className: _App2.default.multipleOptionsContainer },
          _react2.default.createElement(
            'div',
            { className: _App2.default.multipleOptionsItemsContainer },
            _config2.default.switchAccountMenu && this.props.auth.data.account_list.length > 1 && _react2.default.createElement(
              'div',
              { className: _App2.default.accountselector },
              _react2.default.createElement(_reactSelect2.default, {
                id: 'current-account-selector',
                className: _App2.default.selector,
                value: this.props.currentAccount.account_uid,
                onChange: this.changeAccount,
                clearable: false,
                options: this.props.auth.data.account_list.map(function (a) {
                  return { value: a.account_uid, label: a.name + ' ' + (a.account_type === 'Reseller' ? a.account_type : '') };
                })
              }),
              _react2.default.createElement('hr', null)
            ),
            this.state.itemSelected.subItems.map(function (item, index) {
              return _this6.renderSubItems(item, index);
            })
          )
        )
      );
    }
  }]);
  return VerticalMenu;
}(_react2.default.Component), _class3.propTypes = {
  palette: _propTypes2.default.object,
  push: _propTypes2.default.func.isRequired,
  logout: _propTypes2.default.func.isRequired
}, _class3.defaultProps = {
  palette: {
    hover: {
      primary: '#FFFFFF',
      secondary: '#4DC9F4'
    },
    font: {
      primary: '#FFFFFF',
      secondary: '#00377D'
    },
    background: {
      primary: '#00377D',
      secondary: '#FFFFFF'
    },
    button: {
      background: '#FFFFFF'
    }
  }
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'handleLogout', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleLogout'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'changeAccount', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'changeAccount'), _class2.prototype)), _class2)) || _class) || _class);
exports.default = VerticalMenu;
module.exports = exports['default'];