'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class;

var _auth = require('redux/modules/auth');

var _Allow = require('../../containers/Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _components = require('components');

var _materialUiChipInput = require('material-ui-chip-input');

var _materialUiChipInput2 = _interopRequireDefault(_materialUiChipInput);

var _emailValidator = require('email-validator');

var _emailValidator2 = _interopRequireDefault(_emailValidator);

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _reactRouter = require('react-router');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TextField = require('@material-ui/core/TextField');

var _TextField2 = _interopRequireDefault(_TextField);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _reactRedux = require('react-redux');

var _account = require('redux/modules/account');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reachHistory = require('../../helpers/reachHistory');

var _messages = require('redux/modules/messages');

var _MessageTemplateShare = require('./MessageTemplateShare.scss');

var _MessageTemplateShare2 = _interopRequireDefault(_MessageTemplateShare);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MessageTemplateShare = (_dec = (0, _reduxConnect.asyncConnect)([{
    key: 'account',
    promise: function promise(_ref) {
        var _ref$store = _ref.store,
            dispatch = _ref$store.dispatch,
            getState = _ref$store.getState;

        var state = getState();
        if (!state.account.data.reach) {
            return dispatch((0, _account.getAccount)());
        }
        return null;
    }
}, {
    key: "auth",
    promise: function promise(_ref2) {
        var _ref2$store = _ref2.store,
            dispatch = _ref2$store.dispatch,
            getState = _ref2$store.getState;

        if (!(0, _auth.isLoaded)(getState())) {
            return dispatch((0, _auth.load)());
        }
        return null;
    }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
    return {
        account: state.auth.current_account
    };
}, { getAccount: _account.getAccount, pushState: _reachHistory.push, shareTemplate: _messages.shareTemplate }), _dec(_class = _dec2(_class = function (_React$Component) {
    (0, _inherits3.default)(MessageTemplateShare, _React$Component);

    function MessageTemplateShare(props) {
        (0, _classCallCheck3.default)(this, MessageTemplateShare);

        var _this = (0, _possibleConstructorReturn3.default)(this, (MessageTemplateShare.__proto__ || (0, _getPrototypeOf2.default)(MessageTemplateShare)).call(this, props));

        _this.state = {
            send_to: [],
            cc: [],
            description: '',
            variables: '',
            send_on: null,
            template_uid: null,
            date: (0, _moment2.default)().format('YYYY-MM-DD'),
            time: (0, _moment2.default)().format('HH:mm'),
            template_name: null
        };

        return _this;
    }

    (0, _createClass3.default)(MessageTemplateShare, [{
        key: 'componentDidMount',
        value: function componentDidMount() {

            var send_time = this.state.date + 'T' + this.state.time + ':00';
            this.setState({ send_on: send_time });

            try {
                var template_id = this.props.location.state.templateInformation._id;
                var template_name = this.props.location.state.templateInformation.name;
                this.setState({ template_uid: template_id });
                this.setState({ template_name: template_name });
            } catch (e) {
                this.props.pushState({
                    pathname: '/fail',
                    state: {
                        goTo: '/messages/templates',
                        message: (0, _translations2.default)('NO TEMPLATE DATA'),
                        goToModal: false,
                        returnTo: '/messages/templates'
                    }
                });
            }
        }
    }, {
        key: 'addSendTo',
        value: function addSendTo(mail) {
            if (_emailValidator2.default.validate(mail)) {
                this.state.send_to.push(mail);
            } else {
                console.log('ingrese un email válido');
            }
        }
    }, {
        key: 'handleDescription',
        value: function handleDescription(event) {
            var data = this.state;
            data['description'] = event.target.value;
            this.setState({ data: data });
        }
    }, {
        key: 'handleVariables',
        value: function handleVariables(event) {
            var data = this.state;
            data['variables'] = event.target.value;
            this.setState({ data: data });
        }
    }, {
        key: 'deleteSendTo',
        value: function deleteSendTo(mail, index) {
            var sendTo = this.state.send_to;
            sendTo.splice(index, 1);
            this.setState({ send_to: sendTo });
        }
    }, {
        key: 'addCC',
        value: function addCC(mail) {
            if (_emailValidator2.default.validate(mail)) {
                this.state.cc.push(mail);
            } else {
                console.log('ingrese un email válido');
            }
        }
    }, {
        key: 'deleteCC',
        value: function deleteCC(mail, index) {
            var letCcTo = this.state.cc;
            letCcTo.splice(index, 1);
            this.setState({ cc: letCcTo });
        }
    }, {
        key: 'handleDate',
        value: function handleDate(date) {
            this.setState({ date: (0, _moment2.default)(date).format('YYYY-MM-DD') });
        }
    }, {
        key: 'setMinutes',
        value: function setMinutes() {
            var time_minutes = event.target.value;
            this.setState({ time: time_minutes });
            var send_time = this.state.date + 'T' + this.state.time + ':00';
            this.setState({ send_on: send_time });
        }
    }, {
        key: 'share',
        value: function share() {
            var _this2 = this;

            if ((0, _moment2.default)(this.state.send_on).format('YYYY-MM-DD HH:mm') > (0, _moment2.default)().format('YYYY-MM-DD HH:mm')) {
                if (this.state.description != '' && this.send_to != [] && this.variables != '') {
                    var template_uid = this.state.template_uid;
                    var data = {
                        send_to: this.state.send_to,
                        cc: this.state.cc,
                        description: this.state.description,
                        variables: this.state.variables,
                        send_on: (0, _moment2.default)(this.state.send_on).format('DD-MM-YYYY HH:mm')
                    };

                    this.props.shareTemplate(data, template_uid).then(function () {
                        _this2.props.pushState({
                            pathname: '/success',
                            state: {
                                goTo: '/messages/templates',
                                message: (0, _translations2.default)('TEMPLATE SHARED'),
                                goToModal: false,
                                returnTo: '/messages/templates'
                            }
                        });
                    }).catch(function (e) {
                        console.error('error', e);
                    });
                } else {
                    console.log('complete todos los campos para continuar');
                }
            } else {
                console.log('la fecha de envío es menor a la actual');
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _this3 = this;

            var sendToArray = this.state.send_to;
            var CC = this.state.cc;
            var minutes = this.state.time;
            var template_name = this.state.template_name;
            var template_uid = this.state.template_uid;
            return _react2.default.createElement(
                'div',
                { className: _MessageTemplateShare2.default.main },
                _react2.default.createElement(_reactHelmet2.default, { title: (0, _translations2.default)('SHARE SMS TEMPLATE') }),
                _react2.default.createElement(
                    'div',
                    { className: _MessageTemplateShare2.default.body },
                    _react2.default.createElement(
                        'div',
                        { className: _MessageTemplateShare2.default.content },
                        _react2.default.createElement(
                            'div',
                            { className: _MessageTemplateShare2.default.share },
                            (0, _translations2.default)('TEMPLATE'),
                            ': ',
                            template_name,
                            _react2.default.createElement(
                                'div',
                                { className: _MessageTemplateShare2.default.uid },
                                '(',
                                template_uid,
                                ')'
                            )
                        ),
                        _react2.default.createElement(
                            'div',
                            { className: _MessageTemplateShare2.default.sendto },
                            _react2.default.createElement(
                                'div',
                                { className: _MessageTemplateShare2.default.text },
                                (0, _translations2.default)('SEND TO')
                            ),
                            _react2.default.createElement(_materialUiChipInput2.default, {
                                value: sendToArray,
                                onAdd: function onAdd(mail) {
                                    return _this3.addSendTo(mail);
                                },
                                onDelete: function onDelete(mail, index) {
                                    return _this3.deleteSendTo(mail, index);
                                }
                            }),
                            _react2.default.createElement(
                                'div',
                                { className: _MessageTemplateShare2.default.text },
                                'CC:'
                            ),
                            _react2.default.createElement(_materialUiChipInput2.default, {
                                value: CC,
                                onAdd: function onAdd(mail) {
                                    return _this3.addCC(mail);
                                },
                                onDelete: function onDelete(mail, index) {
                                    return _this3.deleteCC(mail, index);
                                }
                            })
                        ),
                        _react2.default.createElement(
                            'div',
                            { className: _MessageTemplateShare2.default.contentDescription },
                            _react2.default.createElement(
                                'div',
                                { className: _MessageTemplateShare2.default.description },
                                _react2.default.createElement(
                                    'div',
                                    { className: _MessageTemplateShare2.default.title },
                                    (0, _translations2.default)('DESCRIPTION')
                                ),
                                _react2.default.createElement('textarea', { className: _MessageTemplateShare2.default.text,
                                    value: this.state.description,
                                    onChange: function onChange(e) {
                                        return _this3.handleDescription(e);
                                    }
                                })
                            ),
                            _react2.default.createElement(
                                'div',
                                { className: _MessageTemplateShare2.default.variables },
                                _react2.default.createElement(
                                    'div',
                                    { className: _MessageTemplateShare2.default.title },
                                    (0, _translations2.default)('AUDIENCE VARIABLES')
                                ),
                                _react2.default.createElement('textarea', { className: _MessageTemplateShare2.default.text,
                                    value: this.state.variables,
                                    onChange: function onChange(e) {
                                        return _this3.handleVariables(e);
                                    }
                                })
                            ),
                            _react2.default.createElement(
                                'div',
                                { className: _MessageTemplateShare2.default.date },
                                _react2.default.createElement(
                                    'div',
                                    { className: _MessageTemplateShare2.default.title },
                                    (0, _translations2.default)('SCHEDULE DATE')
                                ),
                                _react2.default.createElement(
                                    'div',
                                    null,
                                    _react2.default.createElement(_components.Datepicker, {
                                        onChange: function onChange(date) {
                                            return _this3.handleDate(date);
                                        },
                                        timeFormat: false,
                                        compact: true
                                    }),
                                    _react2.default.createElement(
                                        'div',
                                        { className: _MessageTemplateShare2.default.time },
                                        _react2.default.createElement(
                                            'div',
                                            { className: _MessageTemplateShare2.default.timeTitle },
                                            (0, _translations2.default)('HOUR')
                                        ),
                                        _react2.default.createElement(_TextField2.default, {
                                            id: 'time',
                                            type: 'time',
                                            value: minutes,
                                            onChange: this.setMinutes.bind(this),
                                            inputProps: {
                                                step: 300
                                            }
                                        })
                                    )
                                )
                            )
                        ),
                        _react2.default.createElement(
                            'div',
                            { className: _MessageTemplateShare2.default.send },
                            _react2.default.createElement(
                                _reactRouter.Link,
                                { to: '/messages/templates', className: _MessageTemplateShare2.default.link },
                                _react2.default.createElement(_components.Button, { className: _MessageTemplateShare2.default.backbutton, name: (0, _translations2.default)('RETURN') })
                            ),
                            _react2.default.createElement(
                                _Allow2.default,
                                { permissions: ['channels:sms:template:share'] },
                                _react2.default.createElement(_components.Button, { className: _MessageTemplateShare2.default.sendbutton, name: (0, _translations2.default)('SHARE'),
                                    onClick: function onClick() {
                                        return _this3.share();
                                    } })
                            )
                        )
                    )
                )
            );
        }
    }]);
    return MessageTemplateShare;
}(_react2.default.Component)) || _class) || _class);
exports.default = MessageTemplateShare;
module.exports = exports['default'];