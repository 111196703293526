'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _components = require('components');

var _PushSingle = require('./PushSingle/PushSingle');

var _PushSingle2 = _interopRequireDefault(_PushSingle);

var _PushFile = require('./PushFile/PushFile');

var _PushFile2 = _interopRequireDefault(_PushFile);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = require('./Push.scss');

var Push = function (_Component) {
	(0, _inherits3.default)(Push, _Component);

	function Push(props) {
		(0, _classCallCheck3.default)(this, Push);

		var _this = (0, _possibleConstructorReturn3.default)(this, (Push.__proto__ || (0, _getPrototypeOf2.default)(Push)).call(this, props));

		_this.state = {
			single: true,
			tag: false,
			file: false
		};
		return _this;
	}

	(0, _createClass3.default)(Push, [{
		key: 'wtf',
		value: function wtf() {
			console.log(this.props);
			console.log(this.props.location);
		}
	}, {
		key: 'render',
		value: function render() {
			var config = [{
				key: 'single',
				title: 'Envío individual',
				component: _react2.default.createElement(_PushSingle2.default, null),
				disable: false
			}, {
				key: 'tag',
				title: 'Envío por etiqueta',
				component: _react2.default.createElement(_PushSingle2.default, null),
				disable: true

			}, {
				key: 'file',
				title: 'Envío por archivo',
				component: _react2.default.createElement(_PushFile2.default, null),
				disable: false
			}];
			return _react2.default.createElement(
				'div',
				null,
				_react2.default.createElement(_components.CustomTabs, { configuration: config, activeRoute: this.props })
			);
		}
	}]);
	return Push;
}(_react.Component);

exports.default = Push;
module.exports = exports['default'];